<template>
  <div class="contrast">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信贷产品对比</div>
    <div class="contrast-content flex">
      <table cellspacing="0" cellpadding="0">
        <tr>
        <td>产品适用银行</td>
        <td v-for="item in productList" :key="item.productId">{{ replaceBank(item.bankCode) }}</td>
        </tr>        
        <tr>
        <td>产品名称</td>
        <td v-for="item in productList" :key="item.productId">{{item.creditName}}</td>
        </tr>
        <tr>
        <td>产品编号</td>
        <td v-for="item in productList" :key="item.productId">{{item.productId}}</td>
        </tr>
        <tr>
        <td>产品图片</td>
        <td v-for="item in productList" :key="item.productId"><img :src="store.state.imgURL + item.imgUrl"></td>
        </tr>
        <tr>
        <td>是否为专业镇产品</td>
        <td v-for="item in productList" :key="item.productId">{{item.isProcess}}</td>
        </tr>
        <tr>
        <td>产品类型</td>
        <td v-for="item in productList" :key="item.productId">{{ replaceCredit(item.creditType) }}</td>
        </tr>
        <tr>
        <td>产品类型具体描述</td>
        <td v-for="item in productList" :key="item.productId">{{item.creditDescr}}</td>
        </tr>
        <tr>
        <td>适用对象</td>
        <td v-for="item in productList" :key="item.productId">{{ replaceUser(item.userType) }}</td>
        </tr>
        <tr>
        <td>适用对象具体描述</td>
        <td v-for="item in productList" :key="item.productId">{{item.userTypeDescr}}</td>
        </tr>
        <tr>
        <td>担保方式</td>
        <td v-for="item in productList" :key="item.productId">{{ replaceGua(item.guaType) }}</td>
        </tr>
        <tr>
        <td>币种</td>
        <td v-for="item in productList" :key="item.productId">{{item.currencyType}}</td>
        </tr>
        <tr>
        <td>产品简介</td>
        <td v-for="item in productList" :key="item.productId">{{item.creProfile}}</td>
        </tr>
        <tr>
        <td>申办条件</td>
        <td v-for="item in productList" :key="item.productId">{{item.appCondition}}</td>
        </tr>
        <tr>
        <td>利率水平</td>
        <td v-for="item in productList" :key="item.productId">{{item.minRate ? item.minRate+'%~'+item.maxRate + '%': '优惠利率'}}</td>
        </tr>
        <tr>
        <td>融资额度</td>
        <td v-for="item in productList" :key="item.productId">{{item.minLimitAmt ? item.minLimitAmt+'~'+item.maxLimitAmt + '万': '无限制'}}</td>
        </tr>
        <tr>
        <td>融资期限</td>
        <td v-for="item in productList" :key="item.productId">{{item.minYears ? item.minYears+'~'+item.maxYears + '个月': '无限制'}}</td>
        </tr>
        <tr>
        <td>办理流程</td>
        <td v-for="item in productList" :key="item.productId">{{item.maneProcess}}</td>
        </tr>
        <tr>
        <td>需提交材料</td>
        <td v-for="item in productList" :key="item.productId">{{item.subData}}</td>
        </tr>
        <tr>
        <td>其他说明</td>
        <td v-for="item in productList" :key="item.productId">{{item.otherDescr}}</td>
        </tr>
        <tr>
          <td>选择</td>
          <td v-for="(item,i) in productList" :key="item.productId">
            <input :id="'id'+item.productId" type="checkbox" v-model="checkedValue[i]" :value="item.productId">
            <label :for="'id'+item.productId"></label>
          </td>   

        </tr>
      </table>
      

    </div>
    <div style="text-align: center; padding: 4vw">
    <div class="yellowc" @click="apply">需求申请</div>
    </div>
    
  </div>
</template>
<script>
import { Toast,Dialog } from 'vant';
import { ref,reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { apiProCompare,replaceCredit,replaceUser,replaceGua,replaceBank } from '../../api/axios'
export default {
   setup() {
    let router = useRouter();
    let store = useStore();
    console.log(router.currentRoute.value.query,520);
    const productList = reactive([]);
    apiProCompare(router.currentRoute.value.query).then(res => {
       console.log(res,'返回的数据')                   
       if(res.code == '1'){
          res.info.productList.forEach(e => {
            productList.push(e);
          });            
        }
        else {
          Toast(res.msg);
        }
    }).catch(err=>{
        console.log(err,'请求错误') 
    });

    const checkedValue = ref([]);
    const apply = () => {
      
      let arr = [];
      checkedValue.value.forEach((item,i)=>{
        if(item) {
          arr.push(productList[i].productId);
        }
      });
      if(arr.length > 0) {
        const token = store.state.token;        
        if(token) {          
          router.push({
            path: '/apply',
            query: {
              proIds: arr.toString()
            }
          });
        }else {
          Dialog.confirm({
            title: '提示！',
            confirmButtonText: '去登录',
            message:
              '您还没有登录！点击下方按钮去登录。',
            })
            .then(() => {
              router.push({path: '/login'});
            })
            .catch(() => {
              console.log('取消登录!')
          });
        }
      }else {
        Toast('请先选择产品')
      }
      
      
    };
    return { 
      store,
      checkedValue,
      apply,
      productList,
      replaceCredit,
      replaceUser,
      replaceGua,
      replaceBank
    };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.contrast {
  padding-top: 12.5vw;
  background-color: #fff;
  .contrast-content {
    margin: 4vw;
    table {
      width: 100%;
      border-top: 1px solid #d3d2d2;
      border-left: 1px solid #d3d2d2;
      tr {        
        td {
          border-right: 1px solid #d3d2d2;
          border-bottom: 1px solid #d3d2d2;
          min-width: 15vw;
          padding: 2vw;
          font-size: 3.1vw;
          text-align: center;
          position: relative;
          img {
            width: 100%;
          }
          input[type="checkbox"] {
            width: 5.4vw;
            height: 5.4vw;
            opacity: 0;
        }
        
        label {
            position: absolute;
            left: 38%;
            // top: 3px;
            width: 5.4vw;
            height: 5.4vw;
            border-radius: 50%;
            border: 1px solid #999;
        }
        

        input:checked+label { 
            background-color: #ff9900;
            border: 1px solid #ff9900;
        }
        input:checked+label::after {
            position: absolute;
            content: "";
            width: 1.3vw;
            height: 2.7vw;
            top: 0.5vw;
            left: 1.8vw;
            border: 2px solid #fff;
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
        }
        }
      }
      tr:nth-child(2n-1){
        background-color: #f5f5f5;
      }
    }
  }
  .yellowc {
      background: linear-gradient(to right, #ff9900, #ff6700);
      color: #fff;
      font-size: 4.5vw;
      display: inline;
      padding: 1.5vw 5vw;
      border-radius: 6vw;
      margin: 4vw 2vw;
  }
}
</style>
